<template>
  <div class="content">
    <ul>
      <li :class="{ active: index == 0 }" @click="changesIndex(0)">需要服务VIP</li>
      <li :class="{ active: index == 1 }" @click="changesIndex(1)">提供服务VIP</li>
      <li :class="{ active: index == 2 }" @click="changesIndex(2)">滚动广告VIP</li>
      <li :class="{ active: index == 3 }" @click="changesIndex(3)">查看电话VIP</li>
      <li :class="{ active: index == 4 }" @click="changesIndex(4)">信息置顶VIP</li>
    </ul>

    <div class="bar_box">
      <div class="item">
        <span class="title">天会员</span>
        <div class="price">
          每天价格
          <div class="inpputBox">
            <el-input v-model="obj.dayPrice"></el-input>
          </div>
          元
        </div>
        <div class="price">
          发布
          <div class="inpputBox">
            <el-input
              v-model="obj.dayNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>

      <div class="item">
        <span class="title">周会员</span>
        <div class="price">
          每周价格
          <div class="inpputBox">
            <el-input v-model="obj.cirPrice"></el-input>
          </div>
          元
        </div>
        <div class="price">
          发布
          <div class="inpputBox">
            <el-input
              v-model="obj.cirNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
    </div>
    <div class="bar_box">
      <div class="item">
        <span class="title">月会员</span>
        <div class="price">
          每月价格
          <div class="inpputBox">
            <el-input v-model="obj.moonPrice"></el-input>
          </div>
          元
        </div>
        <div class="price">
          发布
          <div class="inpputBox">
            <el-input
              v-model="obj.moonNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>

      <div class="item">
        <span class="title">季会员</span>
        <div class="price">
          每季价格
          <div class="inpputBox">
            <el-input v-model="obj.quaPrice"></el-input>
          </div>
          元
        </div>
        <div class="price">
          发布
          <div class="inpputBox">
            <el-input
              v-model="obj.quaNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
    </div>
    <div class="bar_box">
      <div class="item">
        <span class="title">年会员</span>
        <div class="price">
          每年价格
          <div class="inpputBox">
            <el-input v-model="obj.yearPrice"></el-input>
          </div>
          元
        </div>
        <div class="price">
          发布
          <div class="inpputBox">
            <el-input
              v-model="obj.yearNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
    </div>

    <div class="btn">
      <el-button type="primary" @click="onSubmit" style="width: 120px; height: 60px"
        >确认</el-button
      >
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>您提交的是 {{ text[index] }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="SubmitBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VipConfig, updateVipConfig } from "@/api/config";
export default {
  data() {
    return {
      text: ["需要服务", "提供服务", "滚动广告", "查看电话", "信息置顶"],
      index: 0, //类型0需要服务vip1提供服务vip2滚动广告vip3查看电话vip4信息置顶vip
      list: [],
      obj: {},
      dialogVisible: false,
    };
  },
  mounted() {
    this.getprice();
  },
  methods: {
    async SubmitBtn() {
      let result = this.list.filter((w) => {
        return w.type == this.index;
      });
      let edit = {};
      if (result[0].dayPrice != this.obj.dayPrice) {
        edit.dayPrice = this.$utils.formatPrice1(this.obj.dayPrice);
      }
      if (result[0].dayNum != this.obj.dayNum) {
        edit.dayNum = Number(this.obj.dayNum);
      }
      if (result[0].cirPrice != this.obj.cirPrice) {
        edit.cirPrice = this.$utils.formatPrice1(this.obj.cirPrice);
      }
      if (result[0].cirNum != this.obj.cirNum) {
        edit.cirNum = Number(this.obj.cirNum); //周会员权限数量
      }
      if (result[0].moonPrice != this.obj.moonPrice) {
        edit.moonPrice = this.$utils.formatPrice1(this.obj.moonPrice);
      }
      if (result[0].moonNum != this.obj.moonNum) {
        edit.moonNum = Number(this.obj.moonNum);
      }
      if (result[0].quaPrice != this.obj.quaPrice) {
        edit.quaPrice = this.$utils.formatPrice1(this.obj.quaPrice);
      }
      if (result[0].quaNum != this.obj.quaNum) {
        edit.quaNum = Number(this.obj.quaNum);
      }
      if (result[0].yearPrice != this.obj.yearPrice) {
        edit.yearPrice = this.$utils.formatPrice1(this.obj.yearPrice);
      }
      if (result[0].yearNum != this.obj.yearNum) {
        edit.yearNum = Number(this.obj.yearNum);
      }

      let res = await updateVipConfig({
        vipId: this.obj.vipId, //会员配s置编号
        type: this.index,
        ...edit, //类型0需要服务vip1提供服务vip2滚动广告vip3查看电话vip4信
      });
      if (res.code == "000000") {
        if (res.data.code == "000000") {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.$nextTick(() => {
            this.getprice();
          });
        } else {
          this.$message({
            message: "保存失败",
            type: "error",
          });
          this.dialogVisible = false;
        }
      } else {
        this.$message({
          message: "保存失败",
          type: "error",
        });
        this.dialogVisible = false;
      }
    },
    changesIndex(index) {
      this.index = index;
      this.list.forEach((w) => {
        if (w.type == this.index) {
          this.obj = JSON.parse(JSON.stringify(w));
        }
      });
    },
    onSubmit() {
      this.dialogVisible = true;
    },
    async getprice() {
      let _this = this;
      let res = await VipConfig({ pageStart: 1, pageSize: 10 });
      console.log(res);
      if (res.code == "000000") {
        res.data.records.forEach((w) => {
          console.log(w.type, this.index);
          w.dayPrice = _this.$utils.formatPrice(w.dayPrice);
          w.cirPrice = _this.$utils.formatPrice(w.cirPrice);
          w.moonPrice = _this.$utils.formatPrice(w.moonPrice);
          w.quaPrice = _this.$utils.formatPrice(w.quaPrice);
          w.yearPrice = _this.$utils.formatPrice(w.yearPrice);
          if (w.type == _this.index) {
            console.log(w);
            this.obj = JSON.parse(JSON.stringify(w));
          }
        });
        this.list = res.data.records;
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;

  ul {
    width: 100%;
    height: 92px;
    display: flex;
    li {
      width: 270px;
      height: 92px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #6f6f6f;
      margin-right: 40px;
      text-align: center;
      line-height: 92px;
      transition: all 0.5s;
    }
    .active {
      color: #ffffff;
      background: #7a90f3;
    }
  }
  .bar_box {
    width: 100%;
    height: 90px;
    margin-top: 20px;
    display: flex;
    .item {
      width: 40%;
      //   min-width: 602px;
      height: 90px;
      background-color: #fff;
      margin-right: 18px;
      font-size: 18px;
      display: flex;
      align-items: center;
      padding: 0 24px 0 24px;
      box-sizing: border-box;
      justify-content: space-between;
      border-radius: 6px 6px 6px 6px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      color: #2c2c2c;
      .title {
        font-weight: 700;
      }
      .price {
        display: flex;
        align-items: center;
        .inpputBox {
          width: 62px;
          margin: 0 5px 0 5px;
          height: 42px;
          background: #ffffff;
        }
      }
    }
  }
  .btn {
    width: calc(80% + 18px);
    height: 60px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>
