<template>
  <div class="viewPhone">
    <el-table
      :data="tableData"
      border
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
      class="exporttable"
    >
      <el-table-column
        prop="orderNo"
        label="订单号"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="price"
        label="支付金额"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.price | formatMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderStatus" label="订单状态" align="center">
        <!-- " 0 待支付 1 支付成功 2 支付失败 -->
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">待支付</span>
          <span v-if="scope.row.orderStatus == 1">支付成功</span>
          <span v-if="scope.row.orderStatus == 2">支付失败</span>
          <span></span>
        </template>
      </el-table-column>
      <el-table-column prop="paymenyType" label="支付类型" align="center">
        <!-- "paymenyType": 1, //支付类型 0 余额 1 零钱 -->
        <template slot-scope="scope">
          <span v-if="scope.row.paymenyType == 0">余额</span>
          <span v-if="scope.row.paymenyType == 1">零钱</span>
        </template>
      </el-table-column>
      <!-- "vipType": 3, //会员类型 0 需要服务VIP  1 提供服务VIP  2 滚动广告VIP 3 查看电话VIP 4 信息置顶VIP-->
      <el-table-column prop="vipType" label="会员类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vipType == 0">需要服务VIP</span>
          <span v-if="scope.row.vipType == 1">提供服务VIP</span>
          <span v-if="scope.row.vipType == 2">滚动广告VIP</span>
          <span v-if="scope.row.vipType == 3">查看电话VIP</span>
          <span v-if="scope.row.vipType == 4">信息置顶VIP</span>
        </template>
      </el-table-column>
      <!-- "vipTimeType": 0, //会员时长类型 0 天会员 1 周会员 2 月会员 3 季会员 4 年会员 -->
      <el-table-column prop="vipTimeType" label="会员时长类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vipTimeType == 0">天会员</span>
          <span v-if="scope.row.vipTimeType == 1">周会员</span>
          <span v-if="scope.row.vipTimeType == 2">月会员</span>
          <span v-if="scope.row.vipTimeType == 3">季会员</span>
          <span v-if="scope.row.vipTimeType == 4">年会员</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="用户编号" align="center"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="danger" plain @click="delRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <span @click="detail(scope.row)">查看详情列表</span>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog title="收货地址" :visible.sync="dialogTableVisible" width="70%">
      <el-table :data="diaLogList">
        <el-table-column
          property="orderNo"
          label="订单编号"
          width="150"
        ></el-table-column>
        <el-table-column
          property="title
"
          label="标题"
          width="200"
        >
          <template slot-scope="scope"
            ><span>{{ scope.row.title }}</span></template
          >
        </el-table-column>
        <el-table-column property="amounts" label="金额">
          <template slot-scope="scope"
            ><span>{{ scope.row.amounts | formatMoney }}</span></template
          >
        </el-table-column>
        <el-table-column property="explains" label="操作说明">
          <template slot-scope="scope"
            ><span>{{ scope.row.explains }}</span></template
          >
        </el-table-column>
        <el-table-column property="createTime" label="创建时间">
          <template slot-scope="scope"
            ><span>{{ scope.row.createTime | FormatTime }}</span></template
          >
        </el-table-column>
        <el-table-column property="updateTime" label="更新时间">
          <template slot-scope="scope"
            ><span>{{ scope.row.updateTime | FormatTime }}</span></template
          >
        </el-table-column>
      </el-table>

      <div class="footer_Box">
        <el-pagination
          @size-change="handleSizeChange_dialog"
          @current-change="handleCurrentChange_dialog"
          :current-page="pageStart_log"
          :page-sizes="[10]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_log"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { VipOrder, balanceHis, delvipInf } from "@/api/order";
export default {
  data() {
    return {
      pageSize: 10,
      pageStart: 1,
      total: 0,
      value: "",
      tableData: [],
      dialogTableVisible: false,
      diaLogList: [],
      pageStart_log: 1,
      total_log: 10,
      id: "",
    };
  },
  watch: {
    radio2(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.getList();
    },
    radio1(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    delRow(row) {
      this.$confirm("此操作将永久删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delvipInf({ orderNo: row.orderNo }).then((res) => {
            console.log(res);
            if (res.code == "000000") {
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.message);
            }
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    detail(val) {
      this.dialogTableVisible = true;
      this.id = val.userId;
      this.getDetail();
    },
    getDetail() {
      balanceHis({
        pageStart: this.pageStart_log,
        pageSize: this.pageSize,
        userId: this.id,
      })
        .then((res) => {
          if (res.code == "000000") {
            this.diaLogList = res.data.records;
            this.total_log = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },
    handleSizeChange_dialog(val) {
      this.pageSize = val;
      this.getDetail();
    },
    handleCurrentChange_dialog(val) {
      this.pageStart_log = val;
      this.getDetail();
    },

    getList() {
      let obj = { pageStart: this.pageStart, pageSize: this.pageSize };
      VipOrder(obj)
        .then((res) => {
          if (res.code == "000000") {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  background-color: #fff;
  .footer_Box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .exporttable {
    border: solid 1px #c0c0c0;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      width: 90px;
      height: 60px;
      padding: 0;

      width: 92px;
      height: 52px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span {
      cursor: pointer;
    }
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(1) {
      color: #2c2c2c;
    }
  }
}
</style>
