<template>
  <div class="viewPhone">
    <el-table
      :data="tableData"
      border
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
      class="exporttable"
    >
      <el-table-column
        prop="userId"
        label="用户ID"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="spare1"
        label="用户头像"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <img
            v-if="scope.row.spare1"
            :src="scope.row.spare1"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
          <img
            v-else
            src="http://yfscj.com/default.jpg"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="amounts"
        label="支付金额"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.amounts | formatMoney }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.updateTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="detail(scope.row)">查看详情列表</el-button>
          <el-button type="danger" @click="recharge(scope.row)">充值</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog title="详情" :visible.sync="dialogTableVisible" width="70%">
      <el-table :data="diaLogList">
        <el-table-column
          property="orderNo"
          label="订单编号"
          width="150"
        ></el-table-column>
        <el-table-column
          property="title
  "
          label="标题"
          width="200"
        >
          <template slot-scope="scope"
            ><span>{{ scope.row.title }}</span></template
          >
        </el-table-column>
        <el-table-column property="amounts" label="金额">
          <template slot-scope="scope"
            ><span>{{ scope.row.amounts | formatMoney }}</span></template
          >
        </el-table-column>
        <el-table-column property="explains" label="操作说明">
          <template slot-scope="scope"
            ><span>{{ scope.row.explains }}</span></template
          >
        </el-table-column>
        <!-- v"upType": 0, //变更类型 0 增 1 减 -->
        <el-table-column property="upType" label="变更类型">
          <template slot-scope="scope">
            <span v-if="scope.row.upType == 0" style="color: red">增</span>
            <span v-if="scope.row.upType == 1" style="color: orange">减</span>
          </template>
        </el-table-column>
        <el-table-column property="createTime" label="创建时间">
          <template slot-scope="scope"
            ><span>{{ scope.row.createTime | FormatTime }}</span></template
          >
        </el-table-column>
        <el-table-column property="updateTime" label="更新时间">
          <template slot-scope="scope"
            ><span>{{ scope.row.updateTime | FormatTime }}</span></template
          >
        </el-table-column>
      </el-table>

      <div class="footer_Box">
        <el-pagination
          @size-change="handleSizeChange_dialog"
          @current-change="handleCurrentChange_dialog"
          :current-page="pageStart_log"
          :page-sizes="[10]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_log"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { balanceInf, balanceHis, addMoney } from "@/api/login";
export default {
  data() {
    return {
      pageSize: 10,
      pageStart: 1,
      total: 0,
      value: "",
      tableData: [],
      dialogTableVisible: false,
      diaLogList: [],
      pageStart_log: 1,
      total_log: 10,
      id: "",
    };
  },
  watch: {
    dialogTableVisible(newVal) {
      if (newVal == false) {
        this.pageStart_log = 1;
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    recharge(val) {
      console.log(val);
      this.$prompt("请输入充值金额", "提示:给" + val.userId + " 充值", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (Number(value) == value) {
            this.add({
              userId: val.userId, //用户编号
              amounts: (value * 100).toFixed(2), //
            });
          } else {
            this.$message({
              type: "error",
              message: "数字格式错误",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    async add(data) {
      try {
        let res = await addMoney({
          userId: data.userId, //用户编号
          amounts: data.amounts, //金额(分)
        });
        console.log(res);
        if (res.code == "000000") {
          if (res.data.code == "000000") {
            this.$message({
              type: "success",
              message: "充值成功",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
        console.log(error);
      }
    },
    detail(val) {
      this.dialogTableVisible = true;
      this.id = val.userId;
      this.getDetail();
    },
    getDetail() {
      balanceHis({
        pageStart: this.pageStart_log,
        pageSize: this.pageSize,
        userId: this.id,
      })
        .then((res) => {
          if (res.code == "000000") {
            this.diaLogList = res.data.records;
            this.total_log = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },
    handleSizeChange_dialog(val) {
      this.pageSize = val;
      this.getDetail();
    },
    handleCurrentChange_dialog(val) {
      this.pageStart_log = val;
      this.getDetail();
    },

    getList() {
      let obj = { pageStart: this.pageStart, pageSize: this.pageSize };
      balanceInf(obj)
        .then((res) => {
          console.log(res);
          if (res.code == "000000") {
            console.log(res.data);
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  background-color: #fff;
  .span {
    cursor: pointer;
    color: blue;
  }
  .footer_Box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .exporttable {
    border: solid 1px #c0c0c0;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      width: 90px;
      height: 60px;
      padding: 0;

      width: 92px;
      height: 52px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span {
      cursor: pointer;
    }
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(1) {
      color: #2c2c2c;
    }
  }
}
</style>
