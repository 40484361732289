import { http } from "../utils/http"

export function serviceInflist(data) {
    return http({
        url: '/order/serviceInf/list',
        data: data,
        method: 'post'
    })
}
//充值订单列表

export function VipOrder(data) {
    return http({
        url: '/order/vipInf/list',
        data: data,
        method: 'post'
    })
}
//用户余额列表

export function balanceInf(data) {
    return http({
        url: '/user/balanceInf/list',
        data: data,
        method: 'post'
    })
}
//用户历史余额列表、
export function balanceHis(data) {
    return http({
        url: '/user/balanceHis/list',
        data: data,
        method: 'post'
    })
}
//充值订单删除
export function delvipInf(data) {
    return http({
        url: '/order/vipInf/del',
        data: data,
        method: 'post'
    })
}
//服务订单删除
export function Delpublish(data) {
    return http({
        url: '/order/serviceInf/del',
        data: data,
        method: 'post'
    })
}