<template>
  <div class="content">
    <el-container>
      <el-aside width="280px">
        <div class="h1box">
          <h1 @click="toindex">信息发布</h1>
        </div>
        <el-menu
          :default-active="currentMenu"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          router
          text-color="#C7C7C7"
          active-text-color="#292929"
          background-color="#FEFEFF"
        >
          <el-submenu index="index">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>功能配置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="config">基础配置</el-menu-item>
              <el-menu-item index="price">会员价格</el-menu-item>
              <el-menu-item index="classicon">类目图标</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="index">
            <template slot="title">
              <i
                class="iconfont icon-huiyuan"
                style="
                  font-size: 18px;
                  vertical-align: middle;
                  margin-right: 8px;
                  width: 24px;
                  text-align: center;
                  margin-left: 4px;
                "
              ></i>
              <span>会员管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="need">提供服务</el-menu-item>
              <el-menu-item index="need1">需要服务</el-menu-item>
              <el-menu-item index="banner">滚动广告</el-menu-item>
              <el-menu-item index="phone">会员用户列表</el-menu-item>
              <el-menu-item index="Serviceorder">服务过的订单列表</el-menu-item>
              <el-menu-item index="TopUpOrder">充值订单列表</el-menu-item>
              <el-menu-item index="userbalanceInfoVue">用户余额列表</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header height="99px">
          <div class="info">你好，amdin <span @click="quit">退出</span></div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import cache from "@/utils/cache";
export default {
  data() {
    return {
      currentMenu: "",
    };
  },
  watch: {
    $route(e) {
      console.log(e);
      this.currentMenu = e.name;
    },
  },

  created() {
    this.currentMenu = this.$route.name;
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    setCurrentRoute() {
      this.defaultPath = this.$route.name; //关键   通过他就可以监听到当前路由状态并激活当前菜单
    },
    toindex() {
      this.$router.push("/index");
    },
    quit() {
      this.$confirm("是否退出登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已退出",
          });
          cache.session.remove("token");
          cache.session.remove("userId");
          cache.session.remove("userName");
          cache.session.remove("roleId");
          this.$router.push("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
/* 文字 */
::v-deep.el-submenu.is-active > .el-submenu__title {
  color: #292929 !important;
}
/* icon图标也跟着变 */
::v-deep .el-submenu.is-active > .el-submenu__title i {
  color: #292929 !important;
}

.content {
  width: 100%;
  height: 100vh;

  .el-container {
    width: 100%;
    height: 100%;
    .el-aside {
      .h1box {
        width: 100%;
        height: 99px;

        h1 {
          font-size: 40px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #4e6bef;
          text-align: center;
          line-height: 99px;
        }
      }
    }
    .el-container {
      .el-header {
        .info {
          height: 99px;
          line-height: 99px;
          float: right;
          margin-right: 100px;
          span {
            cursor: pointer;
          }
        }
      }
      .el-main {
        padding: 20px;
        box-sizing: border-box;
        background-color: #f8f8fa;
      }
    }
  }
  //   background-color: gray;
}
</style>
