<template>
  <div class="loginContent">
    <h1 class="login_h1">信息发布小程序后台管理系统</h1>
    <div class="loginWarp">
      <div class="title">账号登录</div>

      <div class="login">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="user">
            <el-input
              type="text"
              v-model="ruleForm.user"
              placeholder="请输入账号"
              required="required"
            >
              <i slot="prefix" class="el-icon-user-solid"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              placeholder="请输入密码"
              required="required"
              show-password
              @keyup.enter.native="toSubmitForm('ruleForm')"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <div class="remeberMe">
            <el-checkbox v-model="checked">记住我</el-checkbox>
            <span></span>
          </div>
          <el-form-item>
            <el-button
              :loading="loading"
              type="primary"
              @click="submitForm('ruleForm')"
              class="submit"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import MD5 from "js-md5";
import { login } from "@/api/login";
import cache from "@/utils/cache";
import Cookies from "js-cookie";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        user: "",
      },
      checked: false,
      rules: {
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
      loading: false,
    };
  },
  created() {
    let obj = {
      user: Cookies.get("user"),
      pass: Cookies.get("pass"),
    };
    console.log(obj);
    this.ruleForm = obj;
    this.checked = true;
  },
  methods: {
    toSubmitForm(e) {
      this.submitForm(e);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        this.loading = true;
        if (valid) {
          try {
            let res = await login({
              loginAccount: this.ruleForm.user, //账号
              loginPassword: MD5(this.ruleForm.pass).toUpperCase(), //密码 MD5 32位大写
            });
            if (res.code == "000000") {
              Cookies.set("loginAccount", res.data.loginAccount);
              cache.session.set("userName", res.data.userName);
              cache.session.set("userId", res.data.userId);
              cache.session.set("token", res.data.token);
              cache.session.set("roleId", res.data.roleId);
              this.$message({
                message: "登录成功",
                type: "success",
              });

              if (this.checked) {
                console.log(this.ruleForm.pass);
                Cookies.set("pass", this.ruleForm.pass);
                Cookies.set("user", this.ruleForm.user);
              } else {
                Cookies.remove("pass");
                Cookies.remove("user");
              }
              this.loading = false;
              setTimeout(() => {
                this.$router.push({
                  path: "/index/config",
                  params: { data: "" },
                });
              }, 500);
            } else {
              this.loading = false;
            }
          } catch (error) {
            console.log(error);
            this.loading = false;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loginContent {
  width: 100%;
  height: 100vh;
  background: url("http://yfscj.com/a.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  position: relative;
  .login_h1 {
    position: absolute;
    font-size: 50px;
    font-family: SimSun-Regular, SimSun;
    font-weight: 400;
    color: #ffffff;
    line-height: 57px;
    right: 10vw;
    top: 14vh;
  }
  .loginWarp {
    position: absolute;
    width: 533px;
    height: 433px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    top: 54vh;
    transform: translateY(-50%);
    right: 9vw;
    padding: 20px 66px 0 66px;
    box-sizing: border-box;
    .login {
      width: 100%;
      height: 100%;
      padding-top: 105px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      .submit {
        width: 100%;
      }
      .remeberMe {
        margin-bottom: 40px;
        width: 100%;
        height: 20px;
        display: inline-flex;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        justify-content: space-between;

        ::v-deep .el-checkbox__label {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
        span {
          cursor: pointer;
        }
      }
    }
    .title {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 186px;
      height: 65px;
      border-bottom: 2px solid #4e6bef;
      font-size: 24px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #4e6bef;
      line-height: 65px;
    }
  }
}
</style>
