<template>
  <div class="config">
    <el-form ref="form" :model="info" label-width="80px" size="mini">
      <div class="bar_box">
        <div class="bar_box_l" style="width: 30%">
          <span>需要服务试用状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.viewProbationStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_l" style="width: 15%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.viewProbation"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          天
        </div>
        <div class="bar_box_l" style="width: 15%; margin-right: 5%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.viewProbationNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
      <div class="bar_box">
        <div class="bar_box_l" style="width: 30%">
          <span>提供服务试用状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.pubProbationStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_l" style="width: 15%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.pubProbation"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          天
        </div>
        <div class="bar_box_l" style="width: 15%; margin-right: 5%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.pubProbationNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
      <div class="bar_box">
        <div class="bar_box_l" style="width: 30%">
          <span>滚动广告试用状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.rollProbationStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_l" style="width: 15%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.rollProbation"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          天
        </div>
        <div class="bar_box_l" style="width: 15%; margin-right: 5%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.rollProbationNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
      <div class="bar_box">
        <div class="bar_box_l" style="width: 30%">
          <span>查看电话试用状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.phoneProbationStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_l" style="width: 15%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.phoneProbation"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          天
        </div>
        <div class="bar_box_l" style="width: 15%; margin-right: 5%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.phoneProbationNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>
      <div class="bar_box">
        <div class="bar_box_l" style="width: 30%">
          <span>信息置顶试用状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.infoProbationStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_l" style="width: 15%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.infoProbation"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          天
        </div>
        <div class="bar_box_l" style="width: 15%; margin-right: 5%">
          <span>试用</span>
          <div class="diy_input">
            <el-input
              v-model="info.infoProbationNum"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </div>
          条
        </div>
      </div>

      <div class="dable_box">
        <div class="dable_box-l">
          <span>查看电话价格</span>

          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.phonePrice"></el-input>
            </div>
            元
          </div>
        </div>
        <div class="dable_box-l">
          <span>查看电话会员价格</span>
          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.phoneVipPrice"></el-input>
            </div>
            元
          </div>
        </div>
      </div>
      <div class="dable_box">
        <div class="dable_box-l">
          <span>需要服务价格</span>

          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.viewPrice"></el-input>
            </div>
            元
          </div>
        </div>
        <div class="dable_box-l">
          <span>需要服务会员价格</span>
          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.viewVipPrice"></el-input>
            </div>
            元
          </div>
        </div>
      </div>
      <div class="dable_box">
        <div class="dable_box-l">
          <span>提供服务价格</span>

          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.pubPrice"></el-input>
            </div>
            元
          </div>
        </div>
        <div class="dable_box-l">
          <span>提供服务会员价格</span>
          <div class="dable_box-l-r">
            每条价格
            <div class="diy_input">
              <el-input v-model="info.pubVipPrice"></el-input>
            </div>
            元
          </div>
        </div>
      </div>

      <div class="bar_box">
        <div class="bar_box_l">
          <span>小程序分享是否开启奖金</span>
          <el-form-item label="">
            <el-radio-group v-model="info.shareStatus">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="bar_box_r">
          <span>奖金来源于被分享者首次充值会员</span>
          <div class="diy_input">
            <el-input
              v-model="info.shareAmount"
              :disabled="info.shareStatus == 1"
            ></el-input>
          </div>
          %
        </div>
      </div>

      <div class="bar_box" style="justify-content: flex-start">
        <span class="titles">信息的显示默认</span>
        <div class="right">
          <el-form-item label="">
            <el-checkbox-group v-model="info.showType" :min="num">
              <!-- <el-checkbox v-for="city in sort" :label="city.val" :key="city.val">{{
                city.name
              }}</el-checkbox> -->
              <el-checkbox label="0">由近到远到全国</el-checkbox>
              <el-checkbox label="1">新信息往前排，老信息往后排</el-checkbox>
              <el-checkbox label="2">我要置顶时，排最顶行或靠顶行</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>
      <div class="bar_box" style="justify-content: flex-start">
        <span class="titles">用户充值方式</span>
        <div class="right">
          <el-form-item label="">
            <el-checkbox-group v-model="info.rechargeType" :min="num">
              <el-checkbox label="0">余额充值</el-checkbox>
              <el-checkbox label="1">零钱充值</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </div>

      <div class="bar_box" style="background: transparent; padding: 0">
        <div
          class="bar_box_l"
          style="background: #fff; padding-left: 24px; box-sizing: border-box"
        >
          <span style="width: 43%">发布信息审核状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.msgReview">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="dable_box-l">
          <span>滚动广告审核状态</span>
          <el-form-item label="">
            <el-radio-group v-model="info.bannerReview">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="bar_box" style="background: transparent; padding: 0">
        <div
          class="bar_box_l"
          style="background: #fff; padding-left: 24px; box-sizing: border-box"
        >
          <span style="width: 43%">首页下面逐条信息显示风格</span>
          <el-form-item label="">
            <el-radio-group v-model="info.styleStatus">
              <el-radio :label="0">卡片风格</el-radio>
              <el-radio :label="1">列表风格</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <!-- <div
          class="bar_box_r"
          style="
            background: #fff;
            width: 49%;
            height: 90px;
            justify-content: space-between;
          "
        >
          <div class="select_color">
            <span>背景颜色</span>
            <el-color-picker v-model="color1" @change="changeColor1"></el-color-picker>
            <span class="colors">{{ color1 }}</span>
          </div>
          <div class="select_color">
            <span>列表颜色</span>
            <el-color-picker v-model="color2"></el-color-picker>
            <span class="colors">{{ color2 }}</span>
          </div>
          <div class="select_color">
            <span>标题颜色</span>
            <el-color-picker v-model="color3"></el-color-picker>
            <span class="colors">{{ color3 }}</span>
          </div>
        </div> -->
        <!-- <div class="dable_box-l">
          <span>发布信息审核是否开启</span>
          <el-form-item label="">
            <el-radio-group v-model="info.msgReview">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div> -->
      </div>

      <el-form-item size="large">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 40px;
          "
        >
          <el-button type="primary" @click="onSubmit('form')" style="width: 120px"
            >确认</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { config, updateConfig } from "@/api/config.js";
export default {
  data() {
    return {
      sort: [
        { name: "由近到远", val: 0 },
        { name: "新信息往前排，老信息往后排", val: 1 },
        { name: "我要置顶时，排最顶行或靠顶行", val: 2 },
      ],
      num: 1,
      info: {
        bannerReview: 0,
        msgReview: 0,
        infoProbationNum: 0,
        infoProbation: 0,
        infoProbationStatus: 0,
        phoneProbationNum: 0,
        phoneProbation: 0,
        phoneProbationStatus: 0,
        rollProbationNum: 0,
        rollProbation: 0,
        rollProbationStatus: 0,
        pubProbationNum: 0,
        pubProbation: 0,
        pubProbationStatus: 0,
        pubVipPrice: "",
        viewProbationNum: 0,
        viewProbationStatus: 0, //是否开启新用户试用期
        viewProbation: 0, //使用天数
        msgReview: 0, //发布信息审核
        phonePrice: 0, //查看电话价格\
        phoneVipPrice: 0,
        viewPrice: 0, //需要服务价格
        viewVipPrice: 0, //
        shareStatus: 0,
        shareAmount: 0,
        showType: 0,
        rechargeType: 0,
        styleStatus: 0,
        backColor: null,
        listColor: null, //列表颜色
        titleColor: null, //标题颜色
      },
      // color1: "选择颜色",
      // color2: "选择颜色",
      // color3: "选择颜色",
    };
  },
  mounted() {
    this.getconfig();
  },
  methods: {
    async onSubmit() {
      let newinfo = JSON.parse(JSON.stringify(this.info));
      newinfo.phonePrice = this.$utils.formatPrice1(newinfo.phonePrice);
      newinfo.phoneVipPrice = this.$utils.formatPrice1(newinfo.phoneVipPrice);
      newinfo.viewPrice = this.$utils.formatPrice1(newinfo.viewPrice);
      newinfo.viewVipPrice = this.$utils.formatPrice1(newinfo.viewVipPrice);
      newinfo.pubPrice = this.$utils.formatPrice1(newinfo.pubPrice);
      newinfo.pubVipPrice = this.$utils.formatPrice1(newinfo.pubVipPrice);
      newinfo.pubPpubVipPricerice = this.$utils.formatPrice1(newinfo.pubPpubVipPricerice);
      newinfo.showType = newinfo.showType.join();
      newinfo.rechargeType = newinfo.rechargeType.join();
      let res = await updateConfig(newinfo);
      if (res.code == "000000") {
        if (res.data.code == "000000") {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.getconfig();
        } else {
          this.$message({
            message: res.data.message,
            type: "success",
          });
        }
      } else {
        this.$message({
          message: "失败了",
          type: "error",
        });
      }
    },
    permissionchange(e) {
      console.log(e);
    },
    async getconfig() {
      let res = await config({ pageStart: 1, pageSize: 10 });
      console.log(res);
      if (res.code == "000000") {
        res.data.records[0].phonePrice = this.$utils.formatPrice(
          res.data.records[0].phonePrice
        );
        res.data.records[0].phoneVipPrice = this.$utils.formatPrice(
          res.data.records[0].phoneVipPrice
        );
        res.data.records[0].viewPrice = this.$utils.formatPrice(
          res.data.records[0].viewPrice
        );
        res.data.records[0].viewVipPrice = this.$utils.formatPrice(
          res.data.records[0].viewVipPrice
        );
        res.data.records[0].pubPrice = this.$utils.formatPrice(
          res.data.records[0].pubPrice
        );
        res.data.records[0].pubVipPrice = this.$utils.formatPrice(
          res.data.records[0].pubVipPrice
        );
        let a = res.data.records[0].showType.split(",");
        let b = res.data.records[0].rechargeType.split(",");
        res.data.records[0].showType = a;
        res.data.records[0].rechargeType = b;
        this.info = res.data.records[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.config {
  width: 100%;
  height: 100%;
  font-size: 18px;
  .dable_box {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .dable_box-l {
      width: 49%;
      height: 90px;
      display: flex;
      padding: 0 24px 0 24px;
      box-sizing: border-box;
      justify-content: space-between;
      background-color: #fff;
      align-items: center;
      border-radius: 6px 6px 6px 6px;
      span {
        font-weight: 700;
      }
      //修改每一个label文字以及边距
      ::v-deep .el-radio__label {
        color: #000;
        // margin-right: 10px;
        font-size: 18px;
      }
      //修改选中label文字
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        // color: #2e2e2e !important;
        font-size: 18px;
        width: 26px;
        height: 26px;
      }
      //修改选中radio背景色、边框
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        font-size: 18px;
      }
      ::v-deep .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin: 0;
      }
      ::v-deep .el-radio__input {
        vertical-align: baseline;
      }
    }
    .dable_box-l-r {
      display: flex;
      align-items: center;
      font-weight: 400;
    }
    .diy_input {
      width: 60px;
      height: 42px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      // border: 1px solid rgba(0, 0, 0, 0.22);
      outline: none;
      padding-left: 2px;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }
  .bar_box {
    width: 100%;
    height: 90px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .dable_box-l {
      width: 49%;
      height: 90px;
      display: flex;
      padding: 0 24px 0 24px;
      box-sizing: border-box;
      justify-content: space-between;
      background-color: #fff;
      align-items: center;
      border-radius: 6px 6px 6px 6px;
      span {
        font-weight: 700;
      }
      //修改每一个label文字以及边距
      ::v-deep .el-radio__label {
        color: #000;
        // margin-right: 10px;
        font-size: 18px;
      }
      //修改选中label文字
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        // color: #2e2e2e !important;
        font-size: 18px;
        width: 26px;
        height: 26px;
        
      }
      //修改选中radio背景色、边框
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        font-size: 18px;
      }
      ::v-deep .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin: 0;
      }
      ::v-deep .el-radio__input {
        vertical-align: baseline;
      }
    }
    .bar_box_l {
      display: flex;
      height: 100%;
      width: 49%;
      align-items: center;
      // justify-content: space-between;
      span {
        font-size: 18px;
        font-weight: 700;
        display: inline-block;
        width: 40%;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      }

      //修改每一个label文字以及边距
      ::v-deep .el-radio__label {
        color: #000;
        // margin-right: 10px;
        font-size: 18px;
      }
      //修改选中label文字
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        // color: #2e2e2e !important;
        font-size: 18px;
        width: 26px;
        height: 26px;
      }
      //修改选中radio背景色、边框
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        font-size: 18px;
      }
      ::v-deep .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin: 0;
      }
      ::v-deep .el-radio__input {
        vertical-align: baseline;
      }
    }
    .bar_box_r {
      width: 49%;
      height: 100%;
      padding: 0 24px 0 24px;
      box-sizing: border-box;
      display: flex;

      align-items: center;

      span {
        font-size: 18px;
        font-weight: 700;
      }
      .diy_input {
        width: 60px;
        height: 42px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        // border: 1px solid rgba(0, 0, 0, 0.22);
        outline: none;
        padding-left: 2px;
        box-sizing: border-box;
        border-radius: 3px;
      }
      .select_color {
        font-size: 18px;
        span {
          font-size: 18px;
        }
        .colors {
          font-weight: 400;
          padding-left: 5px;
        }
        ::v-deep .el-icon-arrow-down:before {
          content: "" !important;
        }
        ::v-deep .el-icon-close:before {
          content: "" !important;
        }
        ::v-deep .el-color-picker__trigger {
          width: 20px;
          padding: 0;
          height: 20px;
          line-height: 24px;
        }
        ::v-deep .el-color-picker {
          // line-height: .5;
          vertical-align: top;
          height: 0;
        }
        // .el-color-picker__icon::before{
        //   display: none;
        // }
      }
    }
    .titles {
      width: 20%;
      font-size: 18px;
      font-weight: 700;
      display: inline-block;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    }
    .right {
      //修改每一个label文字以及边距
      ::v-deep .el-radio__label {
        color: #000;
        // margin-right: 10px;
        font-size: 18px;
      }
      //修改选中label文字
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        // color: #2e2e2e !important;
        font-size: 18px;
        width: 26px;
        height: 26px;
      }
      //修改选中radio背景色、边框
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        font-size: 18px;
      }
      ::v-deep .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin: 0;
      }
      ::v-deep .el-radio__input {
        vertical-align: baseline;
      }
      // 未选中样式
      /deep/ .el-checkbox__inner {
        border-radius: 50%;
        font-size: 18px;
        // background-color:#A8A8A8;
      }
      /deep/ .el-checkbox {
        // color: #a8a8a8;
        font-size: 18px;
      }
      /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        // color: #6c7c92;
        font-size: 18px !important;
      }
      /deep/ .el-checkbox__label {
        font-size: 18px !important;
        color: #000;
      }
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        font-size: 18px;
        background-color: #409eff;
        border-color: #409eff;
      }
    }
  }
}
</style>
