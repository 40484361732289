<template>
  <div class="viewPhone">
    <div class="top">
      <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        :style="{ 'font-size': '14px' }"
      >
        <div style="float: left">
          <el-form-item label="用户ID">
            <el-input v-model="search.userId" placeholder="请输入ID"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.phone"
              placeholder="请输入手机号"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item> </el-form-item>
      </el-form>
      <el-button type="primary" @click="onSubmit" class="btn">查询</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      class="exporttable"
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
    >
      <el-table-column prop="userId" label="用户ID" align="center"> </el-table-column>
      <el-table-column
        prop="spare1"
        label="用户头像"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <img
            v-if="scope.row.spare1"
            :src="scope.row.spare1"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
          <img
            v-else
            src="http://yfscj.com/default.jpg"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
        </template>
      </el-table-column>
      <el-table-column prop="viewStatus" label="需要会员" align="center">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.viewStatus == 0 ? '' : 'red' }">{{
            scope.row.viewStatus == 0 ? "未开通" : "已开通"
          }}</span>
          <div v-if="scope.row.viewStatus == 1" style="margin-top: 10px">
            到期时间 <br />
            {{ scope.row.viewExpTime | FormatTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="pubStatus" label="提供会员" align="center">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.pubStatus == 0 ? '' : 'red' }">{{
            scope.row.pubStatus == 0 ? "未开通" : "已开通"
          }}</span>
          <div v-if="scope.row.pubStatus == 1" style="margin-top: 10px">
            到期时间 <br />
            {{ scope.row.pubExpTime | FormatTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="rollStatus" label="滚动广告" align="center">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.rollStatus == 0 ? '' : 'red' }">{{
            scope.row.rollStatus == 0 ? "未开通" : "已开通"
          }}</span>
          <div v-if="scope.row.rollStatus == 1" style="margin-top: 10px">
            到期时间 <br />
            {{ scope.row.rollExpTime | FormatTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phoneStatus" label="查看电话" align="center">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.phoneStatus == 0 ? '' : 'red' }">{{
            scope.row.phoneStatus == 0 ? "未开通" : "已开通"
          }}</span>
          <div v-if="scope.row.phoneStatus == 1" style="margin-top: 10px">
            到期时间 <br />
            {{ scope.row.phoneExpTime | FormatTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="infoStatus" label="信息置顶" align="center">
        <template slot-scope="scope">
          <span :style="{ color: scope.row.infoStatus == 0 ? '' : 'red' }">{{
            scope.row.infoStatus == 0 ? "未开通" : "已开通"
          }}</span>
          <div v-if="scope.row.infoStatus == 1">
            到期时间 <br />
            {{ scope.row.infoExpTime | FormatTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" align="center"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="parentId" label="推荐人ID" align="center"> </el-table-column>
    </el-table>
    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { userInfolist } from "@/api/login";
export default {
  data() {
    return {
      value: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      pageStart: 1,
      search: {
        userId: "",
        phone: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    onSubmit() {
      this.pageStart = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },

    async getList() {
      let obj = {};
      if (this.search.userId) {
        obj.userId = this.search.userId;
      }
      if (this.search.phone) {
        obj.phone = this.search.phone;
      }

      try {
        let res = await userInfolist({
          pageStart: this.pageStart,
          pageSize: this.pageSize,
          ...obj,
        });
        if (res.code == "000000") {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer_Box {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.demo-form-inline {
  float: left;
}
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  .exporttable {
    border: solid 1px #bbbbbb;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      padding: 0;

      width: 92px;
      height: 40px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(3) {
      color: #2c2c2c;
    }
  }
}
</style>
