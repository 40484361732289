import {http} from "../utils/http"

export function publishlist(data) {
    return http({
        url: '/publish/inf/list',
        data: data,
        method: 'post'
    })
}
export function Uppublishlist(data) {
    return http({
        url: '/publish/inf/up',
        data: data,
        method: 'post'
    })
}
export function delpublish(data) {
    return http({
        url: '/publish/inf/del',
        data: data,
        method: 'post'
    })
}
//查询全部行业分类
export function tradeList(data) {
    return http({
        url: '/trade/inf/list',
        data: data,
        method: 'post'
    })
}