<template>
  <div class="viewPhone">
    <div class="top">
      <!-- <div class="inputbox">
        <el-input placeholder="输入类目进行查询" v-model="value" clearable> </el-input>
      </div>
      <el-button type="primary" class="btn">查询</el-button> -->
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        plain
        style=""
        @click="addtrade"
        class="btn addBtn"
        >添加</el-button
      >
    </div>
    <el-table
      v-loading="pictLoading"
      :data="tableData"
      border
      style="width: 100%; font-size: 18px"
      class="exporttable"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
    >
      <el-table-column
        prop="tradeName
"
        label="类目名称"
        align="center"
      >
        <template slot-scope="scope"
          ><span>{{ scope.row.tradeName }}</span></template
        >
      </el-table-column>
      <el-table-column prop="imgUrl" label="背景" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.imgUrl" alt="" class="table_img" />
        </template>
      </el-table-column>
      <el-table-column prop="showStatus" label="展示状态" align="center">
        <template slot-scope="scope">
          <!-- 展示状态0展示1隐藏 -->
          <span v-if="scope.row.showStatus == 0">展示</span>
          <span v-else>隐藏</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" width="250">
        <template slot-scope="scope">
          <div class="options">
            <span @click="deleteItem(scope.row)">删除</span>
            <span @click="editItem(scope.row)">编辑</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
      :title="show ? '编辑' : '新增类目'"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form :model="obj" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="类目名称" prop="version">
          <el-input
            v-model="obj.tradeName"
            style="width: 200px"
            placeholder="请输入类目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示状态">
          <!-- //展示状态0展示1隐藏 -->
          <el-radio-group v-model="obj.showStatus">
            <el-radio :label="0">展示</el-radio>
            <el-radio :label="1">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number
            v-model="obj.sort"
            @change="handleChange"
            :min="0"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="图片">
          <template>
            <div class="imgList_box" v-if="imgList != []">
              <div v-for="(item, index) in imgList" :key="index">
                <img :src="item" class="avatar imgList" />
                <span
                  class="iconfont icon-changyonggoupiaorenshanchu"
                  @click="removeImg(index)"
                ></span>
              </div>
            </div>
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="uploadAvatar"
            >
              <i class="el-icon-plus avatar-uploader-icon text"></i>
            </el-upload>
          </template>
        </el-form-item>

        <el-form-item>
          <template v-if="show">
            <el-button
              type="primary"
              @click="saveEdit('ruleForm')"
              v-loading.fullscreen.lock="fullscreenLoading"
              >保存</el-button
            >
          </template>
          <template v-else>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              v-loading.fullscreen.lock="fullscreenLoading"
              >立即创建</el-button
            >
          </template>
          <el-button @click="cancel('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  tradeList,
  Deltrade,
  Updatetrade,
  Addtrade,
  uploadImageasync,
} from "@/api/trade.js";
export default {
  data() {
    return {
      pictLoading: true,
      fileList: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: 1,
        desc: "",
        checked: false,
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
      value: "",
      tableData: [],
      pageSize: 10,
      pageStart: 1,
      total: 0,
      fullscreenLoading: false,
      //表单
      ruleForm: {
        version: "", //版本号
        log: "", //更新日志
      },
      show: false,
      obj: {
        sort: 0,
      },
      message: "请上传apk文件",
      fd: {}, //用于放数据  FormData类型
      imgList: [],
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    close() {
      this.obj = {};
      this.imgList = [];
      this.dialogFormVisible = false;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getlist();
    },
    handleChange(e) {
      console.log(e);
    },
    removeImg(e) {
      console.log(e);
      this.obj.imgUrl = "";
      this.imgList = [];
      this.$message.success("删除成功");
    },
    uploadAvatar(file) {
      const isJPG = file.raw.type == "image/jpeg" || file.raw.type == "image/png";
      //  const isLt2M = item.file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
        return;
      }
      this.$message.info("正在上传...");
      console.log(file);

      this.fileUpload(file.raw);
    },
    fileUpload(file) {
      uploadImageasync(file).then((res) => {
        if (res.code == "000000") {
          this.imgList = [];
          this.$message.success("上传成功");
          this.obj.imgUrl = res.data.logoUrl;
          this.imgList.push(res.data.logoUrl);
        }
      });
    },
    deleteItem(row) {
      this.$confirm("此操作将永久删除该类目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Deltrade({ tradeId: row.tradeId });
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getlist();
            } else {
              this.$message({
                message: res.message,
                type: "success",
              });
            }
          } else {
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async saveEdit() {
      console.log(this.obj.imgUrl);
      if (!this.obj.imgUrl) {
        this.$message.error("请上传图片");
        return;
      }
      let a = this.obj;
      let res = await Updatetrade(a);
      if (res.code == "000000") {
        if (res.data.code == "000000") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.obj = {};
          this.dialogFormVisible = false;
          this.imgList = [];
          this.getlist();
        } else {
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      } else {
        this.$message({
          message: res.message,
          type: "success",
        });
      }
    },
    editItem(row) {
      this.dialogFormVisible = true;
      this.show = true;
      let res = JSON.parse(JSON.stringify(row));
      this.imgList.push(res.imgUrl);
      this.obj = res;
    },

    async getlist() {
      let res = await tradeList({
        pageStart: this.pageStart,
        pageSize: this.pageSize,
      });
      if (res.code == "000000") {
        this.pictLoading = false;
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
      console.log(res);
    },
    addtrade() {
      this.show = false;
      this.dialogFormVisible = true;
      this.obj = { ...this.obj, showStatus: 0 };
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.obj.tradeName.trim() == "") {
            this.$message.error("请输入类目名称");
            return;
          }
          if (!this.obj.imgUrl) {
            this.$message.error("请上传图片");
            return;
          }

          let obj = JSON.parse(JSON.stringify(this.obj));
          obj = { ...obj, sort: this.obj.sort };

          Addtrade(obj)
            .then((res) => {
              if (res.code == "000000") {
                if (res.data.code == "000000") {
                  this.$message.success("添加成功");
                  this.obj = {};
                  this.dialogFormVisible = false;
                  this.getlist();
                  this.imgList = [];
                } else {
                  this.$message.error(res.message);
                }
              } else if (res.code == "999999") {
                this.$message.error(res.message);
              }
              console.log(res);
            })
            .catch((err) => {
              this.$message.error("添加失败");
              console.log(err);
            });
        }
      });
    },
    //重置
    cancel() {
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid #ccc;
  border-radius: 12px;
  .text {
    display: inline-block;
    width: 80px;
    line-height: 80px;
  }
}
.imgList_box {
  display: inline-block !important;

  > div {
    display: inline-block !important;
    margin-right: 10px;
    position: relative;
    width: 100px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
    height: 100px;
    > span {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      text-align: center;
      line-height: 108px;
      cursor: pointer;
    }
  }
  > div:hover {
    > span {
      display: inline-block;
    }
  }
}
.footer_Box {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
}
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  .exporttable {
    border: solid 1px #bbbbbb;
  }
  background-color: #fff;
  .table_img {
    width: 100px;
    height: 100px;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      width: 90px;
      height: 60px;
      padding: 0;

      width: 92px;
      height: 52px;
      //   background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
    .addBtn {
      color: #4e6bef;
      width: 162px;
      height: 52px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 2px solid #4e6bef;
    }

    .addBtn:active {
      background: #ecf5ff !important;
      //   font-weight: bold;
    }
    /*按钮悬浮*/
    .addBtn:hover {
      background: #ecf5ff !important;

      //   color: white !important;
      //   font-weight: bold;
      //   border-color: #01a8f9 !important;
    }
    /*按钮点击*/
    .addBtn:focus {
      background: #ecf5ff !important;

      //   color: white !important;
      //   font-weight: bold;

      //   border-color: #01a8f9 !important;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span:nth-child(1) {
      color: #ff0000;
      cursor: pointer;
    }
    span:nth-child(2) {
      color: #003bff;
      cursor: pointer;
    }
  }
}
</style>
