import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import './utils/rest.css'
import './assets/iconfont/iconfont.css'
import utils from '@/utils/utils.js'
import filters from '@/filter/index';
// import '@/utils/resm'
//引入store
import store from './store'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.prototype.$utils = utils
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
