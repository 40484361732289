import axios from "axios"
import { Message } from "element-ui"
import router from '../router'
// import loading from '@/plugins/loading'
import cache from '@/utils/cache'
import md5 from 'js-md5'
let sign = function (param) {
    var json = ''
    var keys = Object.keys(param).sort()
    keys.forEach((data) => {
        if (param[data] === '' || param[data] === null) {
            json = json + ''
        } else {
            json = json + data + '=' + param[data] + '&'
        }
    })
    if (json == '') {
        return 'zzms'
    } else {
        return md5(json + 'zzms88888888').toLocaleUpperCase()
    }
}
if (process.env.NODE_ENV == "development") {
    //开发环境
    axios.defaults.baseURL = "/api"
} else {
    //本地环境http://yfscj.com/manage
    // axios.defaults.baseURL = "/api"
    axios.defaults.baseURL = "http://yfscj.com/manage"
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


const service = axios.create({
    timeout: 120000
})


// 添加请求拦截器
service.interceptors.request.use(function (config) {
    if (cache.session.get('token') != null && cache.session.get('token') != undefined) {
        config.headers['token'] = cache.session.get('token')
        config.headers['userId'] = cache.session.get('userId')
    }
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (!isRepeatSubmit && config.method === 'post') {
        const requestObj = {
            url: config.url,
            data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
            time: new Date().getTime()
        }
        const sessionObj = cache.session.getJSON('sessionObj')
        if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
            cache.session.setJSON('sessionObj', requestObj)
        } else {
            const s_url = sessionObj.url;                  // 请求地址
            const s_data = sessionObj.data;                // 请求数据
            const s_time = sessionObj.time;                // 请求时间
            const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
            if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
                const message = '数据正在处理，请勿重复提交';
                return Promise.reject(new Error(message))
            } else {
                cache.session.setJSON('sessionObj', requestObj)
            }
        }
    }
    return config;
}, function (error) {
    // 对请求错误做些什么

    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(res => {
    if (res.status >= 200 && res.status < 300) {
        console.log(res.data
        );
        return res
    } else {
        console.log(res)
        Message({
            type: 'error',
            message: res.message
        })
        return Promise.resolve(res.message)
    }

}, error => {
    let { message } = error;
    if (message.includes("timeout")) {
        message = "系统接口请求超时";
    }
    Message({
        message: message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error);
});
export function http(params) {
    return new Promise((resolve, reject) => {
        service.defaults.headers.sign = sign(params.data)
        service.defaults.headers.version = '1.0.0'
        service.defaults.headers.sysType = 'system'
        service({
            url: params.url,
            data: params.data,
            method: params.method
        }).then((res) => {
            if (res.data.code == '000000') {
                resolve(res.data)
            } else if (res.data.code == '-A00021') {
                Message({
                    type: 'error',
                    message: '登录信息不存在或已超时,请重新登陆'
                })
                cache.session.remove('token')
                cache.session.remove('userId')
                cache.session.remove('userName')
                cache.session.remove('roleId')
                router.push("/");
            } else {
                Message({
                    type: 'error',
                    message: res.data.message
                })
            }

        }).catch((err) => {
            Message({
                type: 'error',
                message: err.message
            })
            reject(err)
            // loading.closeLoading()
        })
    })
}
