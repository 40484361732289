<template>
  <div class="viewPhone">
    <div class="top">
      <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        :style="{ 'font-size': '14px' }"
      >
        <div style="float: left">
          <el-form-item label="标题">
            <el-input
              v-model="search.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input
              v-model="search.content"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.phone"
              placeholder="请输入手机号"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="展示状态">
            <el-select v-model="showStatus" placeholder="显示状态">
              <!-- 0展示1隐藏 -->
              <el-option key="0" label="展示" :value="0"> </el-option>
              <el-option key="1" label="隐藏" :value="1"> </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item> </el-form-item>
      </el-form>
      <el-button type="primary" @click="onSubmit" class="btn">查询</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      class="exporttable"
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
    >
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        label="描述"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="spare1" label="用户头像" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.spare1"
            :src="scope.row.spare1"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
          <img
            v-else
            src="http://yfscj.com/default.jpg"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
        </template>
      </el-table-column>
      <el-table-column prop="imgUrl" label="详细图" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.imgUrl[0]"
            :preview-src-list="scope.row.imgUrl"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" align="center">
      </el-table-column>
      <el-table-column prop="tradeName" label="所属分类" align="center">
      </el-table-column>
      <el-table-column prop="pubType" label="会员类型" align="center">
        <template slot-scope="scope">
          <!-- //提供服务vip会员类型 0试用会员1天会员2周会员3月会员4季会员5年会员6暂未开通会员 -->
          <span v-if="scope.row.pubType == 0">试用会员</span>
          <span v-if="scope.row.pubType == 1">天会员</span>
          <span v-if="scope.row.pubType == 2">周会员</span>
          <span v-if="scope.row.pubType == 3">月会员</span>
          <span v-if="scope.row.pubType == 4">季会员</span>
          <span v-if="scope.row.pubType == 5">年会员</span>
          <span v-if="scope.row.pubType == 6">暂未开通会员</span> </template
        >te>
      </el-table-column>
      <el-table-column prop="examineStatus" label="审核状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.examineStatus == 0">待审核</span>
          <span v-if="scope.row.examineStatus == 1">审核通过</span>
          <span v-if="scope.row.examineStatus == 2">驳回</span>
        </template>
      </el-table-column>
      <el-table-column prop="orderStatus" label="付款状态" align="center">
        <template slot-scope="scope">
          <!--  0 待支付 1 支付成功 2 支付失败 -->
          <span v-if="scope.row.orderStatus == 0">待支付</span>
          <span v-if="scope.row.orderStatus == 1">支付成功</span>
          <span v-if="scope.row.orderStatus == 2">支付失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center" width="300">
        <template slot-scope="scope">
          <div class="options">
            <el-button size="mini" type="warning" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" type="warning" @click="editServe(scope.row)">
              修改服务
            </el-button>
            <el-button size="mini" type="info" @click="showitem(scope.row)"
              >详情</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="pass(scope.row)"
              v-if="scope.row.examineStatus == 0"
              >通过</el-button
            >
            <el-button
              size="mini"
              type="danger"
              v-if="scope.row.examineStatus == 0"
              @click="reject(scope.row)"
              >驳回</el-button
            >
            <el-button size="mini" type="danger" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      :title="show ? '详情' : '编辑'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标题">
          <el-input
            disabled
            v-model="form.title"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            disabled
            v-model="form.phone"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示状态">
          <el-radio-group v-model="form.showStatus" :disabled="show">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="置顶状态">
          <el-radio-group v-model="form.topStatus" disabled>
            <el-radio :label="0">置顶</el-radio>
            <el-radio :label="1">不置顶</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="创建时间">
          <span>{{ form.createTime | FormatTime }}</span>
        </el-form-item>
        <el-form-item label="照片">
          <div v-for="(item, index) in form.imgUrl" :key="index">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              fit="fit"
            ></el-image>
          </div>
        </el-form-item>
        <el-form-item label="行业分类">
          <!-- 行业分类 -->
          <el-select v-model="form.tradeType" placeholder="请选择">
            <el-option
              v-for="item in tradeListData"
              :key="item.tradeId"
              :label="item.tradeName"
              :value="item.tradeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <!-- //订单状态0待支付1支付中2成功3失败4待发放 -->
          <el-select v-model="form.orderStatus" placeholder="请选择" disabled>
            <el-option
              v-for="item in optionssel"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址">
          <el-col :span="11">
            <el-cascader
              disabled
              size="large"
              :options="options"
              v-model="address"
              :props="defaultParams"
              @change="handleChange"
            >
            </el-cascader>
          </el-col>
        </el-form-item>
        <el-form-item label="会员状态">
          <el-radio-group v-model="form.pubType" disabled>
            <el-radio :label="0">试用会员</el-radio>
            <el-radio :label="1">天会员</el-radio>
            <el-radio :label="2">周会员</el-radio>
            <el-radio :label="3">月会员</el-radio>
            <el-radio :label="4">季会员</el-radio>
            <el-radio :label="5">年会员</el-radio>
            <el-radio :label="6">暂未开通会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" v-model="form.content" disabled></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">保存</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import areaData from "@/assets/js/adress";
import {
  publishlist,
  Uppublishlist,
  delpublish,
  tradeList,
} from "@/api/service";
export default {
  data() {
    return {
      showStatus: 0,
      value: "",
      tableData: [],
      dialogVisible: false,
      form: {},
      options: areaData,
      address: [],
      defaultParams: {
        label: "name",
        value: "name",
        children: "children",
      },
      pageSize: 10,
      pageStart: 1,
      total: 0,
      show: true,
      optionssel: [
        //订单状态 0 待支付 1 支付成功 2 支付失败
        {
          value: 0,
          label: "待支付",
        },
        {
          value: 1,
          label: "支付成功",
        },
        {
          value: 2,
          label: "支付失败",
        },
      ],
      search: {
        title: null,
        phone: null,
        content: null,
      },
      tradeListData: [],
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.gettrade();
  },
  methods: {
    gettrade() {
      tradeList({ pageSize: 999, pageStart: 1 }).then((res) => {
        this.tradeListData = res.data.records;
      });
    },
    deleteRow(row) {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let res = await delpublish({
              pubId: row.pubId,
            });
            if (res.data.code == "000000") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSubmit() {
      this.pageSize = 10;
      this.pageStart = 1;
      this.total = 0;
      this.tableData = [];
      this.getList();
      console.log(this.search);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },

    reject(val) {
      this.$confirm("是否驳回该信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Uppublishlist({
            pubId: val.pubId,
            examineStatus: 2, //审核状态 0 待审核  1 通过  2 驳回
          });
          console.log(res);
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message({
                type: "success",
                message: "驳回成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 修改服务
    editServe(val) {
      this.$confirm("确定要将该信息修改为需要服务么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Uppublishlist({
            pubId: val.pubId,
            pubType: 1,
          });
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message({
                type: "success",
                message: "通过成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    pass(val) {
      this.$confirm("是否通过该信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Uppublishlist({
            pubId: val.pubId,
            examineStatus: 1, //审核状态 0 待审核  1 通过  2 驳回
          });
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message({
                type: "success",
                message: "通过成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    showitem(val) {
      console.log(val);
      this.show = true;
      this.form = JSON.parse(JSON.stringify(val));
      this.dialogVisible = true;
      let arr = [val.province, val.city, val.area];
      this.address = arr;
    },
    handleChange(val) {
      console.log(val);
    },
    submit() {
      if (this.show) {
        this.form = {};
        this.dialogVisible = false;
        return;
      } else {
        this.sendEdit();
      }
    },
    async sendEdit() {
      let res = await Uppublishlist({
        pubId: this.form.pubId, //发布服务编号
        userId: this.form.userId, //用户编号
        pubType: 0, //服务类型 0 提供服务 1 需要服务
        showStatus: this.form.showStatus, //展示状态0展示1隐藏
        title: this.form.title, //标题
        content: this.form.content, //描述
        imgUrl: JSON.stringify(this.form.imgUrl), //图片地址
        phone: this.form.phone, //手机号
        tradeType: this.form.tradeType, //行业编号
        orderStatus: this.form.orderStatus, //订单状态0待支付1支付中2成功3失败4待发放
        examineStatus: this.form.examineStatus, //审核状态 0 待审核  1 通过  2 驳回
        topStatus: this.form.topStatus,
        // tradeType: this.form.tradeType,
      });
      if (res.code == "000000") {
        if (res.data.code == "000000") {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.getList();
          this.form = {};
          this.dialogVisible = false;
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
      this.form = {};
      this.dialogVisible = false;
    },
    edit(val) {
      this.show = false;
      this.form = JSON.parse(JSON.stringify(val));
      this.dialogVisible = true;
      let arr = [val.province, val.city, val.area];
      this.address = arr;
    },
    async getList() {
      let obj = {};
      if (this.search.title) {
        obj.title = this.search.title;
      }
      if (this.search.content) {
        obj.content = this.search.content;
      }
      if (this.search.phone) {
        obj.phone = this.search.phone;
      }
      let res = await publishlist({
        showStatus: this.showStatus,
        pubType: 0, //服务类型 0 提供服务 1 需要服务
        pageStart: this.pageStart,
        pageSize: this.pageSize,
        ...obj,
      });
      console.log(res);
      if (res.code == "000000") {
        this.total = res.data.total;
        res.data.records.forEach((w) => {
          w.imgUrl = JSON.parse(w.imgUrl);
        });
        this.tableData = res.data.records;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer_Box {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
}
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;

  .exporttable {
    border: solid 1px #bbbbbb;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .demo-form-inline {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    ::v-deep .el-input__inner {
      // width: 644px;
      height: 52px;
      border: 1px solid #bbbbbb;
    }
    ::v-deep .el-form-item__label {
      line-height: 52rpx !important;
      margin-top: 8px;
    }
    .btn {
      width: 90px;
      height: 60px;
      padding: 0;

      width: 92px;
      height: 52px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span {
      cursor: pointer;
    }
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(3) {
      color: #2c2c2c;
    }
    span:nth-child(4) {
      color: #003bff;
    }
  }
}
</style>
