import {http} from "../utils/http"
//基础配置
export function config(data) {
    return http({
        url: '/base/config/list',
        data: data,
        method: 'post'
    })
}
//基础配置修改
export function updateConfig(data) {
    return http({
        url: '/base/config/up',
        data: data,
        method: 'post'
    })
}
//会员配置
export function VipConfig(data) {
    return http({
        url: '/vip/config/list',
        data: data,
        method: 'post'
    })
}
//会员配置修改
export function updateVipConfig(data) {
    console.log(data);
    return http({
        url: '/vip/config/up',
        data: data,
        method: 'post'
    })
}