<template>
  <div class="viewPhone">
    <!-- "orderStatus": 1, //订单状态0待支付1支付中2成功3失败4待发放
"serviceType": 2, //信息类型 0 查看需要服务电话 1 查看提供服务电话 2 查看滚动广告电话 -->

    <div>
      <el-radio-group v-model="radio1">
        <el-radio-button :label="-1">全部</el-radio-button>
        <!-- 订单状态 0 待支付 1 支付成功 2 支付失败 -->
        <el-radio-button :label="0">待支付</el-radio-button>
        <el-radio-button :label="1">支付成功</el-radio-button>
        <el-radio-button :label="2">支付失败</el-radio-button>
      </el-radio-group>
    </div>
    <div style="margin-top: 20px; margin-bottom: 20px">
      <el-radio-group v-model="radio2">
        <el-radio-button :label="-1">全部</el-radio-button>
        <el-radio-button :label="0">查看需要服务电话</el-radio-button>
        <el-radio-button :label="1">查看提供服务电话</el-radio-button>
        <el-radio-button :label="2">查看滚动广告电话</el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      :data="tableData"
      border
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
      class="exporttable"
    >
      <el-table-column
        prop="orderNo"
        label="订单号"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="paymenyType"
        label="金额/条数"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <!-- "paymenyType": 2, //支付类型 0 余额 1 零钱 2 会员条数 -->
          <span v-if="scope.row.paymenyType == 0">
            {{ scope.row.price | formatMoney }}元</span
          >
          <span v-if="scope.row.paymenyType == 1"
            >{{ scope.row.price | formatMoney }}元</span
          >
          <span v-if="scope.row.paymenyType == 2">{{ scope.row.price }}条</span>
        </template>
      </el-table-column>
      <el-table-column prop="serviceId" label="服务ID" align="center"> </el-table-column>
      <el-table-column prop="serviceType" label="服务类型" align="center">
        <template slot-scope="scope">
          <!--  0 需要服务 1 提供服务 2 滚动广告 3 查看电话 4 信息置顶 -->
          <span v-if="scope.row.serviceType == 0">需要服务</span>
          <span v-if="scope.row.serviceType == 1">提供服务</span>
          <span v-if="scope.row.serviceType == 2">滚动广告</span>
          <span v-if="scope.row.serviceType == 3">查看电话</span>
          <span v-if="scope.row.serviceType == 4">信息置顶</span>
        </template>
      </el-table-column>
      <!-- "showPhoneType": null, //查看电话类型 0 查看需要服务电话 1 查看提供服务电话 2 查看滚动广告电话 -->
      <el-table-column prop="showPhoneType" label="查看电话类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.showPhoneType == 0">查看需要服务电话</span>
          <span v-if="scope.row.showPhoneType == 1">查看提供服务电话</span>
          <span v-if="scope.row.showPhoneType == 2">查看滚动广告电话</span>
          <span v-if="scope.row.showPhoneType == null">无</span>
        </template>
      </el-table-column>
      <!-- vipType -->
      <el-table-column prop="vipType" label="是否会员" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.vipType == 0">是</span>
          <span v-if="scope.row.vipType == 1">否</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="用户编号" align="center"> </el-table-column>

      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          <el-button type="danger" plain @click="delRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { serviceInflist,Delpublish } from "@/api/order";
export default {
  data() {
    return {
      pageSize: 10,
      pageStart: 1,
      total: 0,
      value: "",
      tableData: [],
      dialogFormVisible: false,
      obj: {},
      radio2: -1,
      radio1: -1,
    };
  },
  watch: {
    radio2(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.getList();
    },
    radio1(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    delRow(row) {
      console.log(row)
      this.$confirm("此操作将永久删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Delpublish({ orderNo: row.orderNo }).then((res) => {
            console.log(res);
            if (res.code == "000000") {
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.message);
            }
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },

    getList() {
      let obj = { pageStart: this.pageStart, pageSize: this.pageSize };
      if (this.radio1 !== -1) obj.orderStatus = this.radio1;
      if (this.radio2 !== -1) obj.serviceType = this.radio2;
      serviceInflist(obj)
        .then((res) => {
          if (res.code == "000000") {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  background-color: #fff;
  .footer_Box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .exporttable {
    border: solid 1px #c0c0c0;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      width: 90px;
      height: 60px;
      padding: 0;

      width: 92px;
      height: 52px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      line-height: 52px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span {
      cursor: pointer;
    }
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(1) {
      color: #2c2c2c;
    }
  }
}
</style>
