const formatPrice = function (val) {
    if (!val) return ''
    if (val == 0) return 0
    return val / 100
}
const formatPrice1 = function (val) {
    if (!val) return 0
    if (val == 0) return 0
    return val * 100
}

export default{
    formatPrice,
    formatPrice1
}