import{ http} from "../utils/http"

export function bannerList(data) {
    return http({
        url: '/banner/inf/list',
        data: data,
        method: 'post'
    })
}
export function Upbanner(data) {
    return http({
        url: '/banner/inf/up',
        data: data,
        method: 'post'
    })
}
export function dleBanner(data) {
    return http({
        url: '/banner/inf/del',
        data: data,
        method: 'post'
    })
}