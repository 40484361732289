<template>
  <div class="content">
    <h1>404 NOT FOUND</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100vh;
  text-align: center;
  h1 {
    padding-top: 200px;
  }
}
</style>