// 引入vue
import Vue from 'vue';
// import { Message } from "element-ui";
// 引入vue-router
import VueRouter from 'vue-router';

import index from '../pages/index.vue'
import Viewphone from '../pages/viewPhone.vue'
import LoginVue from '../pages/login.vue'
import Needserve from '../pages/needserve.vue'
import Banner from '../pages/banner.vue'
import Config from '../pages/config.vue'
import priceVue from '@/pages/price.vue';
import NotFound from '../pages/NotFound.vue'
import ClassIcon from '../pages/ClassIcon.vue'
import provide from '@/pages/provide.vue'
import Serviceorder from '@/pages/Serviceorder.vue'
import TopUpOrder from '@/pages/TopUpOrder.vue'
import UserbalanceInfo from '@/pages/userbalance.vue';
Vue.use(VueRouter)
// 引入index首页页面


// 定义routes路由的集合，数组类型
const routes = [{
    path: "/",
    redirect: 'login',
},

{
    path: "/login",
    name: "login",
    component: LoginVue
},
{
    path: '/index',
    name: 'index',
    component: index,
    redirect: {
        path: "index/config"
    },
    children: [
        {
            path: "config",
            name: "config",
            component: Config
        },
        {
            path: 'phone',
            name: "phone",
            component: Viewphone
        },
        {
            path: 'need',
            name: "need",
            component: Needserve
        },
        {
            path: 'need1',
            name: "need1",
            component: provide
        },
        {
            path: 'banner',
            name: "banner",
            component: Banner
        },
        {
            path: 'price',
            name: 'price',
            component: priceVue
        },
        {
            path: 'classicon',
            name: "classicon",
            component: ClassIcon
        },
        {
            path: 'Serviceorder',
            name: "Serviceorder",
            component: Serviceorder
        },
        {
            path: 'TopUpOrder',
            name: "TopUpOrder",
            component: TopUpOrder
        },
        {
            path: 'userbalanceInfoVue',
            name: "userbalanceInfoVue",
            component: UserbalanceInfo
        },
    ]
},
{
    path: '/404',
    name: "notfound",
    component: NotFound,
    hidden: true
},
// 404 page must be placed at the end !!!
{ path: '*', redirect: '/404', hidden: true }
]

// 实例化VueRouter并将routes添加进去
const router = new VueRouter({
    // ES6简写，等于routes：routes
    routes
});
router.beforeEach((to, from, next) => {
    if (to.path === '/Login') {
        next();
    } else {
        const token = sessionStorage.getItem('token')
        if (!token) {
            next('/Login')
        } else {
            next()
        }
    }


})
// router.

// 抛出这个这个实例对象方便外部读取以及访问
export default router
