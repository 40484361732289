<template>
  <div class="viewPhone">
    <div class="top">
      <el-form
        :inline="true"
        :model="search"
        class="demo-form-inline"
        style="font-size: 14px"
      >
        <div style="float: left">
          <el-form-item label="展示状态">
            <el-select v-model="showStatus" placeholder="显示状态">
              <!-- 0展示1隐藏 -->
              <el-option key="0" label="展示" :value="0"> </el-option>
              <el-option key="1" label="隐藏" :value="1"> </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <el-button type="primary" @click="onSubmit" class="btn">查询</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%; font-size: 18px"
      :cell-style="{ borderColor: '#BBBBBB' }"
      :header-cell-style="{ borderColor: '#BBBBBB' }"
      class="exporttable"
    >
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="content"
        label="描述"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        :show-overflow-tooltip="true"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="spare1"
        label="用户头像"
        :show-overflow-tooltip="true"
        align="center"
      >
        <template slot-scope="scope">
          <img
            v-if="scope.row.spare1"
            :src="scope.row.spare1"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
          <img
            v-else
            src="http://yfscj.com/default.jpg"
            alt=""
            style="width: 100px; height: 100px; border-radius: 50%"
          />
        </template>
      </el-table-column>
      <el-table-column prop="imgUrl" label="详细图" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.imgUrl"
            :preview-src-list="[scope.row.imgUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | FormatTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话" align="center"> </el-table-column>
      <!-- <el-table-column prop="name" label="是否会员" align="center"> </el-table-column> -->
      <el-table-column prop="showStatus" label="展示状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.showStatus == 0">展示</span>
          <span v-else>隐藏</span>
        </template>
      </el-table-column>
      <el-table-column label="付款状态" align="center">
        <!-- //订单状态 0 待支付 1 支付成功 2 支付失败 -->
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus == 0">待支付</span>
          <span v-if="scope.row.orderStatus == 1">支付成功</span>
          <span v-if="scope.row.orderStatus == 2">支付失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="examineStatus" label="审核" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.examineStatus == 0">待审核</span>
          <span v-if="scope.row.examineStatus == 1">审核通过</span>
          <span v-if="scope.row.examineStatus == 2">驳回</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" width="300px">
        <template slot-scope="scope">
          <div class="options">
            <el-button size="mini" type="warning" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="pass(scope.row)"
              v-if="scope.row.examineStatus == 0"
              >通过</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="reject(scope.row)"
              v-if="scope.row.examineStatus == 0"
              >驳回</el-button
            >
            <el-button size="mini" type="danger" @click="deleteRow(scope.row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer_Box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageStart"
        :page-sizes="[10]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog title=" 编辑" :visible.sync="dialogFormVisible">
      <el-form :model="obj" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="滚动广告编号" prop="version">
          <el-input v-model="obj.banId" style="width: 200px" disabled></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="version">
          <el-input v-model="obj.title" style="width: 200px" disabled></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="version">
          <el-input v-model="obj.phone" style="width: 200px" disabled></el-input>
        </el-form-item>
        <el-form-item label="展示状态">
          <!-- //展示状态0展示1隐藏 -->
          <el-radio-group v-model="obj.showStatus">
            <el-radio :label="0">展示</el-radio>
            <el-radio :label="1">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <template>
          <el-form-item label="图片">
            <img :src="obj.imgUrl" alt="" style="width: 100px; height: 100px" />
          </el-form-item>
        </template>

        <el-form-item>
          <template>
            <el-button type="primary" @click="saveEdit('ruleForm')">保存</el-button>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { bannerList, Upbanner, dleBanner } from "@/api/banner";
export default {
  data() {
    return {
      showStatus: 0,
      pageSize: 10,
      pageStart: 1,
      total: 0,
      value: "",
      tableData: [],
      dialogFormVisible: false,
      obj: {},
      search: {
        title: null,
        phone: null,
        content: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    deleteRow(row) {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let res = await dleBanner({
              banId: row.banId, //滚动广告编号
            });
            if (res.data.code == "000000") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSubmit() {
      this.pageStart = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getList();
    },

    reject(val) {
      this.$confirm("是否驳回此信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Upbanner({
            banId: val.banId, //滚动广告编号
            examineStatus: 2, //审核状态 0 待审核  1 通过  2 驳回
          });
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message.success("驳回成功");
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    pass(val) {
      this.$confirm("是否通过此信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await Upbanner({
            banId: val.banId, //滚动广告编号
            examineStatus: 1, //审核状态 0 待审核  1 通过  2 驳回
          });
          if (res.code == "000000") {
            if (res.data.code == "000000") {
              this.$message.success("通过成功");
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          } else {
            this.$message.error("操作失败");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    saveEdit() {
      Upbanner({
        banId: this.obj.banId, //滚动广告编号
        title: this.obj.title, //标题
        imgUrl: this.obj.imgUrl, //图片地址
        phone: this.obj.phone, //手机号
        showStatus: this.obj.showStatus,
      }) //展示状态0展示1隐藏})
        .then((res) => {
          if (res.code == "000000") {
            this.$message.success("保存成功");
            this.obj = {};
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error("保存失败");
          console.log(err);
        });
    },
    edit(val) {
      console.log(val);
      this.obj = JSON.parse(JSON.stringify(val));
      this.dialogFormVisible = true;
    },
    getList() {
      bannerList({
        pageStart: this.pageStart,
        pageSize: this.pageSize,
        showStatus: this.showStatus,
      })
        .then((res) => {
          if (res.code == "000000") {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.footer_Box {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.viewPhone {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  background-color: #fff;
  .exporttable {
    border: solid 1px #c0c0c0;
  }
  .top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    // align-items: center;
    .demo-form-inline {
      height: 52px !important;
      display: flex;
      align-items: center;
      .el-form-item {
        vertical-align: middle;
        margin-top: 10px;
      }
    }
    .inputbox {
      width: 644px;
      height: 52px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      ::v-deep .el-input__inner {
        width: 644px;
        height: 52px;
        border: 1px solid #bbbbbb;
      }
      margin-right: 20px;
    }
    .btn {
      width: 90px;
      height: 40px;
      padding: 0;

      width: 92px;
      height: 40px;
      background: #4e6bef;
      border-radius: 4px 4px 4px 4px;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    span {
      cursor: pointer;
    }
    span:nth-child(1) {
      color: #ff0000;
    }
    span:nth-child(2) {
      color: #003bff;
    }
    span:nth-child(1) {
      color: #2c2c2c;
    }
  }
}
</style>
