
import { http } from "../utils/http"
import axios from "axios"
export function tradeList(data) {
    return http({
        url: '/trade/inf/list',
        data: data,
        method: 'post'
    })
}
export function Deltrade(data) {
    return http({
        url: '/trade/inf/del',
        data: data,
        method: 'post'
    })
}
export function Updatetrade(data) {
    return http({
        url: '/trade/inf/up',
        data: data,
        method: 'post'
    })
}
export function Addtrade(data) {
    return http({
        url: '/trade/inf/add',
        data: data,
        method: 'post'
    })
}
export const uploadImageasync = async (req) => {
    return new Promise((resove, reject) => {
        // axios.defaults.baseURL = "/img"
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                sign: 'zzms',
                version: '1.0.0',
                sysType: 'system',
            },
        };
        const formdata = new FormData()
        formdata.append('addMerchantPortrait', req)
        axios.post('/file/addImg', formdata, config).then(
            res => {
                resove(res.data)
            }
        ).catch(err => {
            reject(err)
        })
    })

}