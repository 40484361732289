import { http } from "../utils/http"

export function login(data) {
    return http({
        url: '/login',
        data: data,
        method: 'post'
    })
}
//查询用户列表
export function userInfolist(data) {
    return http({
        url: '/user/inf/list',
        data: data,
        method: 'post'
    })
}
//用户余额列表
export function balanceInf(data) {
    return http({
        url: '/user/balanceInf/list',
        data: data,
        method: 'post'
    })
}
//用户余额列表
export function balanceHis(data) {
    return http({
        url: '/user/balanceHis/list',
        data: data,
        method: 'post'
    })
}
//用户余额充值
export function addMoney(data) {
    return http({
        url: '/user/balanceInf/addMoney',
        data: data,
        method: 'post'
    })
}